import React from 'react';

function Footer() {
    return (
        <footer className='bg-green2 flex flex-col items-center justify-center py-5 pb-16'>
            <span className='font-light text-white text-2xl'>Realização:</span>
            <img src='./img/mozak.png' alt='Mozak' className='w-[200px] mt-[33px]' />
        </footer>
    );
}

export default Footer;